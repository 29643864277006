<template>
  <div class="container">
    <div class="columns">
      <div class="column is-paddingless">
        <department-tabs />
        <div class="tabs-container content fix-margin">
          <div class="container">
            <div class="columns">
              <div class="column is-12">
                <div class="columns">
                  <div class="column">
                    <h3 class="has-text-centered-mobile is-marginless">
                      Departments List
                    </h3>
                  </div>
                </div>
                <div :class="{ 'hide-edges': hideEdges }">
                  <v-client-table
                    ref="allDepartments"
                    :columns="tableColumns"
                    :options="tableOptions"
                    :data="tableValues"
                  >
                    <!-- coming back to this - making all filters look consistent -->
                    <!-- <template slot="beforeTable">
                      <label class="label">Search table:</label>
                      <div class="field has-addons">
                        <p class="control is-marginless">
                          <textbox
                            id="filter-by"
                            type="text"
                            placeholder="Search"
                            :value="query"
                            @input="updateQuery"
                          />
                        </p>
                      </div>
                    </template> -->

                    <template slot="afterLimit">
                      <download-buttons
                        @clicked="downloadFile"
                      />
                    </template>
                    <template
                      slot="department"
                      slot-scope="props"
                    >
                      <p v-if="[$ADMINISTRATOR, $ACHADMINISTRATOR].includes(curUserType)">
                        <a @click.prevent="editDepartment(props.row)">
                          {{ props.row.name }}
                        </a>
                      </p>
                      <p v-else>
                        {{ props.row.name }}
                      </p>
                    </template>
                    <template
                      slot="contactPhoneNumber"
                      slot-scope="props"
                    >
                      <a :href="`tel:+1-${props.row.contactPhoneNumber}`">{{ props.row.contactPhoneNumber }}</a>
                    </template>
                    <template
                      slot="contactEmail"
                      slot-scope="props"
                    >
                      <p v-if="[$ADMINISTRATOR, $ACHADMINISTRATOR].includes(curUserType)">
                        <a
                          :href="`mailto:${props.row.contactEmail}?subject=${encodeURIComponent('Payment Information Request')}`"
                          target="_blank"
                        >
                          {{ props.row.contactEmail }}
                        </a>
                      </p>
                      <p v-else>
                        <a
                          :href="`mailto:${props.row.contactEmail}?subject=${encodeURIComponent('Payment Information Request')}`"
                          target="_blank"
                        >
                          {{ props.row.contactEmail }}
                        </a>
                      </p>
                    </template>
                    <template
                      slot="onlyAdmin"
                      slot-scope="props"
                    >
                      This is an only admin column.
                      {{ props }}
                    </template>
                  </v-client-table>
                </div>
              </div>
            </div>
          </div>
        </div>
        <modal
          ref="edit-department"
          class="is-small"
          :settings="{ title: 'Edit Department Information' }"
        >
          <ValidationObserver
            v-slot="validation"
            tag="div"
          >
            <input-form :errors="validation.errors">
              <div class="columns">
                <div class="column is-full">
                  <p class="has-text-centered">
                    Updating information for:
                    <br>
                    <strong>
                      {{ department.number }} - {{ department.name }}
                    </strong>
                  </p>
                  <div class="field">
                    <div class="control">
                      <vee-textbox
                        v-model="department.contactName"
                        name="Contact Name"
                        placeholder="Contact Name"
                        rules="required"
                        required
                      />
                    </div>
                  </div>
                  <div class="field">
                    <div class="control">
                      <vee-textbox
                        v-model="department.contactTitle"
                        name="Contact Title"
                        placeholder="Contact Title"
                        rules="required"
                        required
                      />
                    </div>
                  </div>
                  <div class="field">
                    <div class="control">
                      <vee-textbox
                        v-model="department.contactEmail"
                        name="Contact E-mail"
                        placeholder="Contact E-mail"
                        rules="required|email"
                        required
                      />
                    </div>
                  </div>
                  <div class="field">
                    <div class="control">
                      <vee-textbox
                        v-model="department.contactPhoneNumber"
                        name="Contact Phone Number"
                        placeholder="Contact Phone Number"
                        rules="required|validPhone"
                        required
                        @keyup="formatPhone"
                      />
                    </div>
                  </div>
                  <div class="field">
                    <div class="control has-text-centered">
                      <button
                        class="button is-primary"
                        :class="{ 'is-loading': isLoading }"
                        :disabled="isLoading"
                        @click.prevent="submitForm(validation)"
                      >
                        Update
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </input-form>
          </ValidationObserver>
        </modal>
      </div>
    </div>
  </div>
</template>
<script>
import DownloadButtons from "@/components/DownloadButtons";
import Modal from '@/components/common/Modal';
import DepartmentTabs from '@/components/DepartmentTabs';

import { options } from '@/config/tableOptions';

import {
  Textbox,
} from '@phila/phila-ui';

import {
  extend,
  withValidation,
  ValidationObserver,
  setInteractionMode,
} from 'vee-validate';

import { required, email } from 'vee-validate/dist/rules';
const VeeTextbox = withValidation(Textbox);

export default {
  name: "DepartmentsFinance",
  components: {
    VeeTextbox,
    ValidationObserver,
    Modal,
    DownloadButtons,
    DepartmentTabs,
  },
  data() {
    return {
      isLoading: false,
      department: {
        name: '',
        number: '',
        id: '',
        contactName: '',
        contactTitle: '',
        contactEmail: '',
        contactPhoneNumber: '',
      },
      tableColumns: [],
      tableOptions: options({
        columnsClasses: {
          contactEmail: 'td-nowrap',
          contactPhoneNumber: 'td-nowrap',
        },
        headings: {
          contactName: "Contact",
          contactTitle: "Title",
          contactPhoneNumber: "Phone",
          contactEmail: "Email",
          actions: "",
        },
        filterable: [ 'name', 'number', 'contactName', 'contactTitle', 'contactEmail', 'contactPhoneNumber' ],
        resizableColumns: true,
      }),
      curUserData: null,
    };
  },
  computed: {
    tableValues() {
      return this.$store.state.departments.departments || [];
    },
  },
  async fetch({ store }) {
    await store.dispatch('departments/getAllDepartments');
    await store.dispatch('users/getCurUserData');
    return Promise.resolve();
  },
  created () {
    setInteractionMode('passive');
    let self = this;
    extend('required', {
      ...required,
      message: 'The {_field_} field is required',
    });
    extend('email', {
      ...email,
    });
    extend('validPhone', {
      validate: value => {
        const regex = new RegExp(/\(\d{3}\)\s\d{3}\s-\s\d{4}/);
        return regex.test(value);
      },
      message: (_, values) => 'phone number error',
      // message: (_, values) => this.$t('form.phoneNumberFormatErrorMsg', values),
    });
  },
  beforeMount() {
    this.tableColumns = [
      "department",
      "contactName",
      "contactPhoneNumber",
      "contactEmail",
    ];
    if (this.isAdmin) {
      this.tableColumns.push("contactTitle");
      // this.tableColumns.push("onlyAdmin");
      // this.tableColumns.push("actions");
    }
  },
  methods: {
    formatPhone (event) {
      console.log('DepartmentsFinance.vue formatPhone is running');
      if (event.keyCode !== 8) {
        this.department.contactPhoneNumber = this.$helpers.formatPhone(this.department.contactPhoneNumber);
      }
    },
    downloadFile(type) {
      const fileName = `departments`;

      /**
       * Prepare Object to Download
       */
      let data = this.tableValues.map(d => [
        String(d.name),
        String(d.contactName),
        String(d.contactPhoneNumber),
        String(d.contactEmail),
      ]);

      data.unshift([
        "Department",
        "Contact",
        "Phone",
        "E-mail",
      ]);

      if (type === 'csv') {
        this.$helpers.downloadCSV(data, fileName);
      } else {
        this.$helpers.downloadPDF(
          data,
          fileName,
          `Departments List`,
          { 2: { cellWidth: 'wrap' }, 3: { cellWidth: 'wrap' }}
        );
      }

      data = null;
    },
    resetForm() {
      const data = this.$options.data();
      Object.assign(this.department, data.department);
    },
    editDepartment(department) {
      this.resetForm();
      Object.assign(this.department, department);
      this.$refs['edit-department'].show();
      this.formatPhone({ 'keycode': 1 });
    },
    async submitForm(validation) {
      const isValid = await validation.validate();
      if (!isValid) {
        return;
      }

      this.isLoading = true;
      if (!this.department.id) {
        this.$warning(`Oops! something went wrong, please refresh the page and try again.`);
        this.isLoading = false;
        return;
      }
      const result = await this.$store.dispatch('departments/update', this.department);
      if (result) {
        this.$success('Department Successfully Updated!');
        this.isLoading = false;
        this.$refs['edit-department'].hide();
      }
    },
  },
};
</script>
